import React from 'react';
import loader from '../style/Images/loader_nw.gif';

const Loader = () => {
  return (
    <div style={{
      float: 'left',
      width: '100%',
      background: "rgba(255, 255, 255,0.70)",
      height: "100%",
      zIndex: 999,
      position: "fixed",
    }}>
      <img style={{position: 'absolute', top: '50%', left: 0, right: 0, margin: '0 auto'}} src={loader} alt="loader"/>
    </div>
  );
};

export default Loader;
